/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Navbar from '../components/navbar/navbar';
import MobileNavbar from '../components/mobile-navbar/mobile-navbar';
import HeroSection from '../components/landing-page/hero';
import InlineForm from '../components/landing-page/inline-form';
import CardSection from '../components/landing-page/card-section';
import ParagraphWithIcon from '../components/landing-page/paragraph-with-icon';

import './index.scss';

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPageLanding(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription {
        metaDescription
      }
      socialMediaImage {
        file {
          url
        }
      }
      language
      slug
      section {
        ...HeroSection
        ...InlineForm
        ...ParagraphWithIcons
        ...CardSection
      }
    }
  }
`;

const Index = ({ data }) => {
  const { section } = data.contentfulPageLanding;

  const components = {
    ContentfulComponentCard: CardSection,
    ContentfulComponentHeroSection: HeroSection,
    ContentfulComponentInlineForm: InlineForm,
    ContentfulComponentIconSection: ParagraphWithIcon
  };

  const ComponentMatch = componentData => {
    const MatchingComponent = components[componentData.internal.type];
    return <MatchingComponent data={componentData} />;
  };

  return (
    <Layout>
      <Navbar color="white" />
      <MobileNavbar color="white" />
      <main className="page-home">
        {section.map((component, i) => (
          <ComponentMatch {...component} key={`component-${i}`} />
        ))}
      </main>
    </Layout>
  );
};

export default Index;
