/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { graphql } from 'gatsby';

import { LayoutUI, Button } from '../../ui-kit';

export const query = graphql`
  fragment CardSection on ContentfulComponentCard {
    internal {
      type
    }
    heading
    cards {
      image {
        file {
          url
        }
      }
      title
      colors
      link {
        link
        text
      }
      subtitle
      cardSize
    }
  }
`;

const FullImageFoundation = ({ className, id, data, ...rest }) => {
  const { heading, cards } = data;

  return (
    <section className="component--cards" key={heading} id="cards">
      <LayoutUI>
        {heading && <h2 className="cards__heading">{heading}</h2>}
        <div className="card__wrapper">
          {cards.map(({ image, link, colors, title, cardSize, subtitle }) => (
            <div
              className={`card--relative ${
                cardSize ? 'card--half' : 'card--quarter '
              }`}
              key={title}
            >
              <div
                className={`card__idunno ${
                  !image ? 'card--full-bleed' : `card--captioned`
                }`}
                data-hover-style={`${image ? 'zoom' : 'border'}`}
              >
                <div
                  className={`${
                    image && cardSize
                      ? 'card__bg-image'
                      : `card__color  card__color--${colors}`
                  }
                    ${
                      image && !cardSize
                        ? 'card__bg-image card__sm-image'
                        : `card__color  card__color--${colors}`
                    }
                    `}
                  style={{
                    backgroundImage:
                      image && image.file && `url(${image.file.url})`
                  }}
                />
                <a href={link.link} className="card__anchor--positioned">
                  <div
                    className={`card__content card__background-image ${
                      colors !== 'None' && image ? `card__color--${colors}` : ''
                    } ${image ? 'card__content--bottom ' : ''}`}
                  >
                    <h3
                      className={`card__title ${
                        (image && image.file && !subtitle) || !image
                          ? 'card__title--big'
                          : ''
                      }`}
                    >
                      {title}
                    </h3>
                    {subtitle && <p className="card__subtitle">{subtitle}</p>}
                    {!image && link && link.text && (
                      <Button color="gray">{link.text}</Button>
                    )}
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </LayoutUI>
    </section>
  );
};

export default FullImageFoundation;
