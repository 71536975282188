/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import { graphql } from 'gatsby';

import {
  Checkbox,
  LayoutUI,
  LeadText,
  Stack,
  TextStyle,
  TextField,
  Button
} from '../../ui-kit';

import './inline-form/inline-form.scss';

export const query = graphql`
  fragment InlineForm on ContentfulComponentInlineForm {
    heading
    subheading
    buttonText
    internal {
      type
    }
  }
`;

const InlineForm = ({ data }) => {
  const { heading, subheading, buttonText } = data;

  const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/7208022/o52ul9g/';
  const [daily, setDaily] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [honeyPot, setHoneyPot] = useState('');

  const submitForm = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    if (honeyPot.length === 0) {
      fetch(ZAPIER_URL, {
        method: 'POST',
        body: data
      })
        .then(() => {
          setSuccessMessage(
            'Welcome! So happy to have you part of this community. Your vitamin for the soul is on it’s way! 😊'
          );
        })
        .catch(() => {
          setSuccessMessage(
            "Whoops! Something went wrong and we didn't catch that. 🙃 Refresh the page and try again. If it continues to not work, reach out at hello@projecthappiness.org. We'll get is squared away!"
          );
        });
    }
  };

  return (
    <LayoutUI narrow className="inline-form__container">
      <Stack alignment="center" distribution="center" vertical>
        <h2>
          <TextStyle color="Black">{heading}</TextStyle>
        </h2>
        <LeadText>
          {subheading && <TextStyle color="Black">{subheading}</TextStyle>}
        </LeadText>
        <TextStyle color="red">
          {!successMessage ? (
            <Stack vertical>
              <form
                // action={ZAPIER_URL}
                // method="post"
                target="_blank"
                id="inline-form"
                className="inline-form"
                // type="POST"
                onSubmit={e => submitForm(e)}
                // success_location="https://projecthappiness.org/newsletter-success/"
              >
                <div className="CheckboxContainer">
                  <Checkbox
                    checked={daily}
                    color="red"
                    inactiveState="red-outline"
                    label="Daily Inspiration"
                    name="daily"
                    className="inspiration"
                    onChange={() => setDaily(!daily)}
                  />
                  <Checkbox
                    checked={weekly}
                    color="red"
                    inactiveState="red-outline"
                    label="Weekly Re-Cap"
                    name="weekly"
                    className="recap"
                    onChange={() => setWeekly(!weekly)}
                  />
                  <Checkbox
                    checked={honeyPot}
                    color="red"
                    inactiveState="red-outline"
                    label="Honey Pot"
                    name="honeyPot"
                    className="ohnohoney"
                    onChange={() => setHoneyPot(!honeyPot)}
                  />
                </div>
                <div className="input__container">
                  <TextField
                    label="First Name"
                    color="red"
                    onChange={e => setFirstName(e.target.value)}
                    name="firstName"
                    value={firstName}
                  />
                  <TextField
                    label="Last Name"
                    color="red"
                    onChange={e => setLastName(e.target.value)}
                    name="lastName"
                    value={lastName}
                  />
                  <TextField
                    // button="&rarr;"
                    label="email"
                    color="red"
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    value={email}
                  />
                  <Button primary color="red" type="submit">
                    {buttonText}
                  </Button>
                </div>
              </form>
            </Stack>
          ) : (
            <p>{successMessage}</p>
          )}
        </TextStyle>
      </Stack>
    </LayoutUI>
  );
};

export default InlineForm;
