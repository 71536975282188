/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { graphql } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import VimeoPlayer from 'react-player/lib/players/Vimeo';

import {
  ColoredSection,
  Header,
  HeroImage,
  HeroVideo,
  LayoutUI,
  LeadText,
  Button,
  Stack,
  TextStyle
} from '../../ui-kit';

export const query = graphql`
  fragment HeroSection on ContentfulComponentHeroSection {
    title
    images {
      file {
        url
      }
      title
      description
    }
    subtitle {
      subtitle
    }
    video {
      file {
        url
        fileName
        contentType
      }
    }
    vimeoLink
    link {
      link
      text
    }
    textColor
    opacity
    internal {
      type
    }
  }
`;

const FullImageFoundation = ({ data }) => {
  const {
    title,
    subtitle,
    images,
    textColor,
    video,
    link,
    opacity,
    vimeoLink
  } = data;

  if (video && !vimeoLink) {
    return (
      <HeroVideo
        src={video.file.url}
        imageSrc={
          images[0].file && images[0].file.fileName
            ? images[0].file.fileName
            : ''
        }
        backgroundColor="#cf9c5b"
      >
        <LayoutUI narrow>
          <Stack alignment="center" distribution="center" vertical>
            <h1 className="Heading">
              <TextStyle color={textColor || `white`}>{title}</TextStyle>
            </h1>
            <LeadText>
              {subtitle && (
                <TextStyle color={textColor || `white`}>
                  {subtitle.subtitle}
                </TextStyle>
              )}
            </LeadText>
            {link &&
              link.map(({ link, text }) => (
                <Button color="red" href={link} key={text}>
                  {text}
                </Button>
              ))}
          </Stack>
        </LayoutUI>
      </HeroVideo>
    );
  } else if (!video && !vimeoLink && images && images.length === 1) {
    return (
      <HeroImage
        height="large"
        image={images[0].file.url}
        noMargin
        overlay={opacity ? 'medium' : ''}
      >
        <LayoutUI narrow>
          <Stack alignment="center" distribution="center" vertical>
            <h1 className="Heading">
              <TextStyle color={textColor || `white`}>{title}</TextStyle>
            </h1>
            {subtitle && (
              <LeadText>
                <TextStyle color={textColor || `white`}>
                  {subtitle.subtitle}
                </TextStyle>
              </LeadText>
            )}
            {link &&
              link.map(({ link, text }) => (
                <Button color="red" href={link} key={text}>
                  {text}
                </Button>
              ))}
          </Stack>
        </LayoutUI>
      </HeroImage>
    );
  } else if (vimeoLink) {
    return (
      <ColoredSection color="gray-lighter" id="video">
        <div className="VideoContainer">
          <LayoutUI fullWidth>
            <Stack alignment="center" spacing="loose" vertical>
              <Stack.Item>
                <Header>{title}</Header>
              </Stack.Item>
              <Stack.Item fullWidth>
                <div className="VideoWrapper">
                  <VimeoPlayer
                    url={vimeoLink}
                    className="Video"
                    width="100%"
                    height="100%"
                    controls
                  />
                </div>
              </Stack.Item>
            </Stack>
          </LayoutUI>
        </div>
      </ColoredSection>
    );
  }
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      autoPlay
      interval={6000}
      useKeyboardArrows
      infiniteLoop
    >
      {images &&
        images.map(entity => (
          <div key={entity.file.url}>
            <img src={entity.file.url} alt={title} />
            <LayoutUI narrow>
              <Stack alignment="center" distribution="center" vertical>
                <h1 className="Heading">
                  <TextStyle color={textColor || `white`}>{title}</TextStyle>
                </h1>
                {subtitle && (
                  <LeadText>
                    <TextStyle color={textColor || `white`}>
                      {subtitle.subtitle}
                    </TextStyle>
                  </LeadText>
                )}
                {link &&
                  link.map(({ link, text }) => (
                    <Button color="red" href={link} key={text}>
                      {text}
                    </Button>
                  ))}
              </Stack>
            </LayoutUI>
          </div>
        ))}
    </Carousel>
  );
};

export default FullImageFoundation;
