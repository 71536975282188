/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import Markdown from 'react-markdown';
import { graphql } from 'gatsby';

import { LayoutUI, TextStyle } from '../../ui-kit';

export const query = graphql`
  fragment ParagraphWithIcons on ContentfulComponentIconSection {
    heading
    paragraph {
      paragraph
    }
    iconHeading
    headingColor
    textColor
    icons {
      color
      link {
        link
        text
      }
      image {
        file {
          url
          fileName
        }
      }
    }
    internal {
      type
    }
  }
`;

const FullImageFoundation = ({ className, id, data, ...rest }) => {
  const {
    heading,
    headingColor,
    textColor,
    paragraph,
    iconHeading,
    icons
  } = data;

  return (
    <section className="icon__component">
      <LayoutUI>
        <h2 className="icon-section__title">
          <TextStyle color={headingColor}>{heading}</TextStyle>
        </h2>
        <p className="icon-section__paragraph">
          <TextStyle color={textColor}>
            <Markdown source={paragraph.paragraph} />
          </TextStyle>
        </p>
        <h2 className="icon-section__title">
          <TextStyle color={headingColor}>{iconHeading}</TextStyle>
        </h2>
        <div className="icon__container">
          {icons.map(icon => (
            <a
              href={icon.link.link}
              key={icon.link.text}
              className="icon__single"
            >
              <img
                className={`icon__image icon__border  icon__border--${icon.color}`}
                src={icon.image.file.url}
                alt={icon.image.file.title}
              />
              <h3
                className={`icon__description  icon__description--${icon.color}`}
              >
                {icon.link.text}
              </h3>
            </a>
          ))}
        </div>
      </LayoutUI>
    </section>
  );
};

export default FullImageFoundation;
